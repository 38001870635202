import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {API, graphqlOperation} from 'aws-amplify';
import {Amplify} from 'aws-amplify';
import awsconfig from '../../../src/aws-exports';
import {onCreateMenuItemsSentToBar} from '../../../src/graphql/subscriptions';
import {deleteMenuItemsSentToBar, updateMenuItemsPurchased} from '../../../src/graphql/mutations';
import {listMenuItemsSentToBars} from '../../../src/graphql/queries';
import {listMenuItemsSentToBarsWithData} from '../../../src/graphql/customQueries';
import styled from 'styled-components';
import Table from 'react-bootstrap/Table';
import {formatMoney} from '../../utils/utils';
import { VendorUserContext } from '../../context/VendorUserContext';
import "bootstrap/dist/css/bootstrap.min.css";

const PageWrap = styled.div`
    margin: 40px 40px;
    display: flex;
    flex-direction: column;
`;

const HeaderWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
`
const Title = styled.div`
    font-size: 24px;
`
const HomeButton = styled.button`
`
const Spacer = styled.div`
    margin-top: 15px;
`

const TableRowButton = styled.button`
  margin-top: 0px;
`

Amplify.configure(awsconfig);

const RecievedDrinksAtBar = () => {
  const [drinks, setDrinks] = useState([]);
  const navigate = useNavigate();
  const { userVendorSelected } = useContext(VendorUserContext);

  const getSentDrinks = async () => {
    const drinks = await API.graphql(graphqlOperation(listMenuItemsSentToBarsWithData));
    const drinkData = drinks.data.listMenuItemsSentToBars.items;
    console.log('drinkData', drinkData)
    const thisBarsDrinks = drinkData.filter((item) => item.item.vendorID === userVendorSelected.id);
    console.log('thisBarsDrinks', thisBarsDrinks)
    setDrinks(drinkData);
  }

  useEffect(() => {
    getSentDrinks();

    const subscirption = API.graphql(graphqlOperation(onCreateMenuItemsSentToBar)).subscribe({
      next: (data) => {
        console.log('data', data)
        const newDrink = data.value.data.onCreateMenuItemsSentToBar;

        setDrinks((prevDrinks) => {
          const updatedDrinks = [...prevDrinks, newDrink];
          console.log('updatedDrinks', updatedDrinks);
          return updatedDrinks;
        });
      }
    })
    return () => subscirption.unsubscribe();
  }, [])

  const handleFulfilled = async (drink) => {
    // delete from MenuItemsSentToBar
    console.log('drink', drink)
    const deleteDrink = await API.graphql(graphqlOperation(deleteMenuItemsSentToBar, {input: {id: drink.id}}));
    if (deleteDrink) {
        // mark as redeemed in MenuItemsPurchased
        const drinkStateHistory = JSON.parse(drink.item.stateHistory); //[...drink.stateHistory]
        drinkStateHistory.push({drink_status: 'redeemed', date: new Date().getTime()})
        await API.graphql(graphqlOperation(
            updateMenuItemsPurchased, 
            {
                input: {
                    id: drink.item.id, 
                    stateHistory: JSON.stringify(drinkStateHistory), 
                    state: 'redeemed'
                }
            }
        ))
        const existingDrinks = drinks.filter((item) => item.id !== drink.id);
        setDrinks(existingDrinks);
        return;
    } else {
        console.log('error deleting drink')
    }
  }

  const displayDrinks = () => {
    return drinks.map((drink, index) => {
      return (
        
        <tr key={index}>
          <td>{drink.item.menuItemName}</td>
          <td>{drink.receiverName}</td>
          <td>${formatMoney(drink.item.tip)}</td>
          <td>${formatMoney(drink.item.drinkCost)}</td>
          <td>
            <TableRowButton onClick={() => handleFulfilled(drink)}>Mark Fulfilled</TableRowButton>
          </td>
        </tr>
        
      )
    })
  }
  return (
    <PageWrap>
      <HeaderWrap>
        <Title>Drinks</Title>
        <HomeButton onClick={() => navigate('/menuItems')}>Home</HomeButton>
      </HeaderWrap>
      <Table striped bordered hover variant="dark">
        <thead>
            <tr>
                <th>Drink</th>
                <th>Name</th>
                <th>Tip</th>
                <th>Value</th>
                <th>Action</th>
            </tr>
        </thead>
        <tbody>
            {displayDrinks()}
        </tbody>
      </Table>
    </PageWrap>
  )
}

export default RecievedDrinksAtBar;